@import '../../../styles/Theme.Colors.module.scss';
@import '../../../styles/Theme.Mixins.module.scss';

.contact_details {
	display: grid;
	grid-template-rows: auto 1fr;
	row-gap: $DefaultPadding;
	.contact_details_header_container {
		@include default-box-header();
		display: grid;
		align-items: center;
		justify-items: self-start;
		column-gap: $DefaultPadding;
		grid-template-columns: auto 1fr;
		&:hover {
			cursor: pointer;
		}
	}

	.contact_details_container {
		display: grid;
		column-gap: $DefaultPadding;
		grid-template-columns: repeat(3, 33%);
		.details_container_one {
			@include default-box();
			display: grid;
			align-content: baseline;
			text-align: left;
			ul {
				list-style-type: none;
				font-size: $DefaultFontSize;
				font-weight: $DefaultFontWeight;
				margin-bottom: 0px;
				margin-left: -30px;
				li {
					display: grid;
					grid-template-columns: 30% 70%;
					margin-bottom: rem-calc(20px);
				}
				li:last-child {
					margin-bottom: 0px;
				}
			}
		}
		.details_container_two {
			@include default-box();
			display: grid;
		}
	}
}
