@import '../../../../styles/Theme.Colors.module.scss';
@import '../../../../styles/Theme.Mixins.module.scss';

input.text_box {
	background: $White;
	border-radius: $DefaultBorderRadius;
	border: 1px solid darken($Spindle, 20%);
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
	color: $Cello;
	font-size: $DefaultFontSize;
	padding: rem-calc(4px);
	&:focus {
		border: 1px solid $Spindle;
		outline: none;
	}
	&.bold {
		font-weight: $DefaultFontWeight;
	}
	&.disabled {
		background: $WhiteLilac;
	}
	&.search {
		background-image: url('../../../../../public/search.png');
		background-size: rem-calc(20px);
		background-position: rem-calc(5px);
		background-repeat: no-repeat;
		padding-left: rem-calc(30px);
	}

	&.error {
		@include border-and-shadow($HorOffset: 0, $VerOffset: 0, $BlurRadius: rem-calc(4px), $BorderColour: red);
		border: 2px solid $Red;
	}
}
