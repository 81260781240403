@import '../../../styles/Theme.Colors.module.scss';
@import '../../../styles/Theme.Mixins.module.scss';

.segments_table_container {
	@include scroll-bar();
	border-radius: $DefaultBorderRadius;
	height: 66vh;
	table {
		border: 1px solid $TableHeadBackground;
		box-shadow: $DefaultBoxShadow;
	}
}
