@import '../../../styles/Theme.Colors.module.scss';
@import '../../../styles/Theme.Mixins.module.scss';

.contacts_list_container {
	@include default-box();
	display: grid;
	align-items: center;
	align-content: center;
	column-gap: $DefaultPadding;
	grid-template-columns: auto auto 1fr;
	justify-items: flex-start;
	.title {
		display: grid;
	}
	.search {
		display: grid;
	}
	.record_number {
		display: grid;
		font-size: $DefaultFontSize;
	}
}
