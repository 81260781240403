@import '../../../../../styles/Theme.Colors.module.scss';
@import '../../../../../styles/Theme.Mixins.module.scss';

body {
	background: $White;
}

.container {
	margin: rem-calc(100px) 0 rem-calc(100px) 0;
	text-align: center;
	&.no_margin {
		margin: 0 !important;
	}
}

.progress {
	padding: 4px;
	border-radius: $DefaultBorderRadius * 4;
	background: darken($Geyser, 10%);
	box-shadow: inset 0 0px 2px rgba(0, 0, 0, 0.25), 0 0px rgba(255, 255, 255, 0.08);
}

.progress_bar {
	height: rem-calc(22px);
	border-radius: $DefaultBorderRadius * 4;
	background-image: linear-gradient(to bottom, $ScienceBlue, $ScienceBlue);
	transition: 0.2s linear;
	transition-property: width, background-color;
	.progress_bar_text {
		color: $White;
		font-size: $DefaultFontSize;
		font-weight: $DefaultFontWeight;
		padding-left: rem-calc(8px);
		text-align: left;
		position: relative;
		top: rem-calc(3px);
		.re_calculate {
			@include fade-in-out();
		}
		span {
			position: absolute;
			width: rem-calc(240px);
		}
	}
}

.progress_moved .progress_bar {
	// width: 85%;
	background-color: $ChetwodeBlue;
	// animation: progressAnimation 6s;
}

@keyframes progressAnimation {
	0% {
		width: 0%;
		background-color: $Spindle;
	}
	100% {
		width: 85%;
		background-color: $ChetwodeBlue;
	}
}
