@import '../../../../styles/Theme.Colors.module.scss';
@import '../../../../styles/Theme.Mixins.module.scss';

.separator {
	position: relative;
	margin-bottom: rem-calc(16px);
	margin-top: rem-calc(16px);
	&.no_top_space {
		margin-top: 0px;
	}
	&.no_bottom_space {
		margin-bottom: 0px;
	}
	&:before {
		@include content-separator();
	}
}
