@use 'sass:math' as math;

@import '../../../styles/Theme.Colors.module.scss';
@import '../../../styles/Theme.Mixins.module.scss';

.segment_builder {
	display: grid;
	row-gap: $DefaultPadding;
	text-align: left;
	.segments_header_container {
		padding: rem-calc(6px);
		height: fit-content;
		.segments_header_left {
			width: 70%;
		}
		.segments_header_right {
			width: 30%;
		}
	}
	.segment_builder_container {
		@include animate(height);
		@include scroll-bar();
		height: 81vh;
		overflow: auto;
		padding: 12px 12px 12px 0px !important;
		&.show_table {
			height: 56vh;
		}
		.segment_filter_groups_header_title {
			@include sticky();
			background: $TableHeadBackground;
			border-radius: $DefaultBorderRadius $DefaultBorderRadius 0 0;
			border-bottom: 2px solid $Spindle;
			padding: rem-calc(12px);
			top: rem-calc(-12px);
			label {
				color: $TableHeadFontColour;
				font-weight: $DefaultTableHeadFontWeight;
			}
		}
		.segment_filter_groups_header_content {
			background: $ContainerFillBackground;
			padding: rem-calc(12px);
		}
		.segment_filter_groups {
			background: $ContainerFillBackground;
			box-shadow: $DefaultBoxShadow;
			padding: 0 rem-calc(12px) rem-calc(12px) rem-calc(12px);
		}
		.second_column {
			width: $SegmentBuilderSecondColumnWidth;
		}
		.third_column {
			width: $SegmentBuilderThirdColumnWidth;
		}
		.median_value_selections {
			margin-bottom: -20px;
		}
	}

	.contact_table_label_container {
		display: grid;
		align-self: flex-end;
		height: 31vh !important;
		margin-top: 100vh;
		// padding: 12px !important;
		row-gap: math.div($DefaultPadding, 2);
		z-index: 99;
		&.show_table {
			margin-top: 0vh;
			margin-bottom: 0px;
		}
		.left_container {
			display: grid;
			width: 160%;
		}
		.right_container {
			width: 40%;
		}
		.contact_table_container {
			@include scroll-bar();
			overflow: auto;
		}
	}
}
