@import '../../../../styles/Theme.Colors.module.scss';
@import '../../../../styles/Theme.Mixins.module.scss';

.segment_filter_record_type_container {
	display: grid;
	&__modal {
		row-gap: rem-calc(12px);
	}
	label {
		&:hover {
			cursor: pointer;
		}
	}
}
