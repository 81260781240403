@import '../../../../styles/Theme.Colors.module.scss';
@import '../../../../styles/Theme.Mixins.module.scss';

.body_only,
.default_body {
	border-radius: 0px 0px $DefaultBorderRadius $DefaultBorderRadius !important;
	tr {
		border-radius: 0px 0px $DefaultBorderRadius $DefaultBorderRadius !important;
		text-align: left;
		td {
			background: $ContainerFillBackground;
			color: $Cello;
			padding: rem-calc(16px);
			position: relative;
			vertical-align: middle;
		}
		td:first-child:before {
			left: 20px;
			right: 0;
		}
		td:last-child:before {
			left: 0;
			right: 20px;
		}
	}
	tr:last-child {
		border: 1px solid transparent;
		td:first-child {
			border-radius: 0px 0px 0px $DefaultBorderRadius !important;
		}
		td:last-child {
			border-radius: 0px 0px $DefaultBorderRadius 0px !important;
		}
	}
}

.body_only {
	tr:first-child {
		td:first-child {
			border-radius: $DefaultBorderRadius 0px 0px 0px !important;
		}
		td:last-child {
			border-radius: 0px $DefaultBorderRadius 0px 0px !important;
		}
	}
}

.body_only.single_row {
	tr:first-child {
		border: 1px solid transparent;
		td:first-child {
			border-radius: $DefaultBorderRadius 0px 0px $DefaultBorderRadius !important;
		}
		td:last-child {
			border-radius: 0px $DefaultBorderRadius $DefaultBorderRadius 0px !important;
		}
	}
}
