@import '../../../styles/Theme.Colors.module.scss';
@import '../../../styles/Theme.Mixins.module.scss';

.contacts_table_container {
	border-radius: $DefaultBorderRadius;
	&.paging {
		height: 83vh;
	}

	.contacts_table {
		padding: rem-calc(4px);
		&.paging {
			@include scroll-bar();
			max-height: 80vh;
			overflow: auto;
		}
		table {
			border: 1px solid $TableHeadBackground;
			box-shadow: $DefaultBoxShadow;
		}
	}
}
