@use 'sass:color';

// CX Data Primary Colours
$DodgerBlue: #00b3ff;
$KeyLimePie: #b0cb1f;
$Turbo: #ffed00;

$Anakiwa: #b2e7ff;
$Botticelli: #d8e0ee;
$CatskillWhite: #f5f8fa;
$Cello: #1f4565;
$ChetwodeBlue: #8faadc;
$CuttySark: #597281;
$Geyser: #c8d3d9;
$GullGray: #a2b4be;
$Lime: #32cd32;
$LimedSpruce: #3d4750;
$LinkWater: #dae3f3;
$Mystic: #e2e8eb;
$PeriwinkleGray: #c8d4ea;
$PoloBlue: #94adda;
$Red: rgba(200, 0, 0, 1);
$Spindle: #b6c8e7;
$ScienceBlue: #0052cd;
$White: white;
$WhiteLilac: #edf1f8;

$Black: #000000;
$MineShaft: #333333;
$White: #ffffff;

/* Primary Palette */
$CloudGrey: #c5dbf7;
$DarkSteel: #222a31;
$Steel: #465363;
$Yella: #e8b650;

/* Secondary Palette */
$BrightOrange: #ff7f39;
$DuckBlue: #bbddf6;
$Ghost: #cbd2d9;
$LimedSpruce: #323f4a;
$SpaceBlue: #1b365f;

/* Tertiary */
$Lime: #a5ba00;
$Red: #d0334c;
/* Gradients */
$SteelYellaGradient: linear-gradient(90.84deg, #465363 0.1%, #e8b650 50.05%, #465363 100%);

/// lighten a color
@function tint($color, $percentage) {
	@return mix(white, $color, $percentage);
}

/// Darken color
@function shade($color, $percentage) {
	@return mix(black, $color, $percentage);
}
