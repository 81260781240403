@import url('https://fonts.googleapis.com/css?family=Poppins');
@import './styles/Theme.Colors.module.scss';
@import './styles/Theme.Mixins.module.scss';

body {
	background: $Cello !important;
	margin: 0;
	font-family: 'Poppins' !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	height: 100vh;
	overflow-x: hidden !important;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h3 {
	font-size: rem-calc(18px) !important;
	font-weight: $DefaultFontWeight;
	margin: 0 !important;
}

.dropdown-menu.show {
	@include scroll-bar();
	overflow-y: overlay !important;
	width: 100%;
}

.help_scout_beacon {
	position: absolute;
	bottom: rem-calc(100px);
}

::selection {
	background: $PrimaryColourTwo;
}
