@import '../../../../styles/Theme.Mixins.module.scss';

.layout {
	@include flex-row-layout();
	@include medium-gap();

	justify-content: space-between;

	&.two_column_right_prominent {
		@include no-gap();

		flex-wrap: wrap;
	}

	//TODO: This needs refactoring (top spacing / attributes)
	&.two_column_left_prominent {
		@include no-gap();

		flex-wrap: wrap;

		> :not(:last-child) {
			@include flex-column-layout();
			@include small-right-margin();

			width: auto;
			flex-grow: 1;
			justify-content: left !important;
		}

		> :last-child {
			@include flex-row-layout();
			@include flex-full-center();

			width: max-content;
		}
	}

	&.auto_grow {
		& > :last-child {
			flex-grow: 1;
			min-width: rem-calc(200px);
		}
	}

	&.space {
		& > :first-child {
			@include medium-right-margin();
		}
	}

	&.space_plus {
		& > :first-child {
			@include medium-plus-right-margin();
		}
	}
}
