@import '../../../../styles/Theme.Colors.module.scss';

// bootstrap spinner overrides
.spinner-grow:nth-child(1) {
	color: $PrimaryColourOne;
}

.spinner-grow:nth-child(2) {
	color: $PrimaryColourTwo;
}

.spinner-grow:nth-child(3) {
	color: $PrimaryColourThree;
}
