@import '../styles/Theme.Colors.module.scss';
@import '../styles/Theme.Mixins.module.scss';

.page_layout {
	width: 100%;
	height: 100vh;
	overflow: hidden !important;
}

.left_container {
	@include animate(margin-left);
	z-index: 100;
	background: $LeftContainerBackground;
	box-shadow: $DefaultBoxShadow;
	width: $LeftContainerWidth;
	margin-left: 0px;
	padding-top: rem-calc(10px);

	.logo_container {
		height: 15%;
		margin-left: 10px;
		&.not_signed_in {
			height: 8%;
		}
		button {
			margin: 8px -12px 0 0;
		}
	}
	.navigation {
		height: 80%;
		padding-top: 2vh;
	}

	.authentication {
		height: 10%;
		padding-bottom: 2vh;
	}
	&.collapsed {
		margin-left: -$LeftContainerWidth;
	}
}

.right_container {
	background: $RightContainerBackground;
	padding: 8px;
	width: 100%;
}
