@import '../../../styles/Theme.Colors.module.scss';
@import '../../../styles/Theme.Mixins.module.scss';

.filter_options_modal_container {
	font-size: $DefaultFontSize;
	.add_text_container {
		display: grid;
	}
	.add_text_container_two_col {
		display: grid;
		column-gap: $DefaultPadding;
		grid-template-columns: 3fr auto;
	}
	.radio_button {
		border: 1px solid red;
	}
}
