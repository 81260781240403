@import '../../../../styles/Theme.Colors.module.scss';
@import '../../../../styles/Theme.Mixins.module.scss';

.date_input {
	background: $White;
	box-shadow: $DefaultBoxShadow;
	font-size: $DefaultFontSize;
	&__error {
		@include border-and-shadow($HorOffset: 0, $VerOffset: 0, $BlurRadius: rem-calc(4px), $BorderColour: red);
		border: 2px solid red;
	}
}
