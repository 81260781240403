@import '../../../../styles/Theme.Colors.module.scss';
@import '../../../../styles/Theme.Mixins.module.scss';

.container_disabled_overlay {
	@include border();

	background-color: $OverlayDisabledBackgroundColor;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: 70%;

	&.adjuster_overlay_title {
		top: 30px; //TODO: This isnt working at the moment
	}
}

.container {
	@include flex-row-layout();

	&.small {
		width: rem-calc(300px);
		min-height: rem-calc(75px);
		height: max-content;
	}

	&.medium {
		width: rem-calc(600px);
		min-height: rem-calc(150px);
		height: max-content;
	}

	&.large {
		width: rem-calc(100%);
		min-height: rem-calc(250px);
		height: max-content;
	}

	&.xlarge {
		width: rem-calc(100%);
		min-height: 50vh;
		height: max-content;
	}

	&.shadow {
		@include border-and-shadow();
	}

	&.fill {
		background-color: $ContainerFillBackground;
	}

	&.border {
		@include border();
	}

	&.divider {
		border-bottom: rem-calc(3px) solid $SectionDivider;
	}

	&.full_width {
		@include full-width();
	}

	&.page_width {
		@include negate-client-area-full-width();
	}

	&.auto_width {
		flex: 1 0 auto;
	}

	&.stretch_height {
		height: unset;
	}

	&.prominent_fill {
		background-color: $ContainerProminentFillBackground;
	}

	&.top_space {
		@include medium-top-margin();
	}

	&.top_space_small {
		@include small-top-margin();
	}

	&.top_space_large {
		@include large-top-margin();
	}

	&.top_space_xlarge {
		@include xlarge-top-margin();
	}

	&.left_space {
		@include medium-left-margin();
	}

	&.left_space_large {
		@include large-left-margin();
	}

	&.right_space {
		@include medium-right-margin();
	}

	&.right_space_large {
		@include large-right-margin();
	}

	&.bottom_space {
		@include medium-bottom-margin();
	}

	&.bottom_space_large {
		@include large-bottom-margin();
	}

	&.adjuster_title_no_top_space {
		margin-top: rem-calc(15px);
	}

	&.adjuster_title_with_top_space {
		@include xlarge-top-margin();
	}

	&.adjuster_relative {
		position: relative !important;
	}

	&.sticky {
		@include sticky();
	}
}

.container_client {
	@include flex-column-layout();
	@include full-width();

	&.horizontal {
		@include flex-row-layout();
	}

	&.padding {
		@include medium-full-padding();
	}

	&.padding_large {
		@include large-full-padding();
	}

	&.padding_xlarge {
		@include xlarge-full-padding();
	}

	&.center {
		@include flex-align-center();
	}

	&.center_full {
		@include flex-full-center();
	}

	&.center_left {
		@include flex-left-center();
	}

	&.bottom_left {
		@include flex-align-start();
		@include flex-justify-bottom();
	}

	&.right {
		@include flex-align-end();
		@include flex-justify-right();
	}

	&.left {
		@include flex-align-start();
	}

	&.prominent_padding {
		@include large-full-padding();
	}

	&.bottom_padding_large {
		@include large-bottom-padding();
	}

	&.wrap {
		flex-wrap: wrap;
	}

	&.adjuster_title {
		margin-top: rem-calc(25px);
	}

	&.adjuster_child_space_horizonal,
	&.adjuster_child_space_vertical {
		@include medium-gap();
	}

	&.adjuster_child_space_horizonal_large,
	&.adjuster_child_space_vertical_large {
		@include large-gap();
	}
}

.container_title {
	background-color: $ContainerTitleBackColor;
	border-radius: rem-calc(6px);
	font-weight: $DefaultFontWeight;
	font-size: $DefaultFontSize;
	margin-top: rem-calc(-15px);
	margin-left: rem-calc(10px);
	padding: rem-calc(8px) rem-calc(12px) rem-calc(8px) rem-calc(12px);
	position: absolute;
}
