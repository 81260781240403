@import '../../../../styles/Theme.Colors.module.scss';
@import '../../../../styles/Theme.Mixins.module.scss';

ul.navigation_container {
	color: $DefaultFontColour;
	font-size: $DefaultFontSize;
	font-weight: $DefaultFontWeight;
	list-style-type: none;
	margin-left: 0;
	padding: 0 rem-calc(24px) 0 rem-calc(24px);
	text-align: left;
	li {
		border-radius: $DefaultBorderRadius;
		padding: 8px 12px 8px 12px;
		margin-bottom: rem-calc(16px);
		&:hover {
			background: $PeriwinkleGray;
			color: $ScienceBlue;
			cursor: pointer;
			svg {
				color: $ScienceBlue;
			}
		}
	}
	li.selected {
		background: $NavigationListItemBackground;
		border-left: 4px solid $ScienceBlue;
		color: $ScienceBlue;
		svg {
			color: $ScienceBlue;
		}
	}
	li.separator {
		background: none;
		padding: 0;
		cursor: default;
		// margin-bottom: rem-calc(-8px);
	}
	svg {
		color: $IconColour;
	}
}
