@import '../../../../styles/Theme.Colors.module.scss';
@import '../../../../styles/Theme.Mixins.module.scss';

.drop_down {
	@include border-and-shadow($HorOffset: 0, $VerOffset: 0, $BlurRadius: rem-calc(4px));
	float: left;
	button.show {
		background-color: darken($LinkWater, 20%) !important;
		width: 100%;
	}
	button {
		background-color: $DropDownBackground !important;
		border: none;
		color: $Cello !important;
		font-size: $DefaultFontSize;
		text-align: left;
		overflow-x: hidden;
		overflow-y: auto;
		width: 100%;
		padding: rem-calc(4px) 0px rem-calc(4px) rem-calc(8px);
		&::after {
			text-align: right;
			float: right;
			color: $Cello;
			margin: rem-calc(8px) rem-calc(8px) 0 0;
		}
		&:hover {
			background-color: $ScienceBlue !important;
			color: $White !important;
		}
	}

	div {
		background-color: $White !important;
		button {
			background-color: $White !important;
			&:hover {
				background-color: $ScienceBlue !important;
			}
		}
		max-height: rem-calc(150px) !important;
	}

	&__error {
		@include border-and-shadow($HorOffset: 0, $VerOffset: 0, $BlurRadius: rem-calc(4px), $BorderColour: red);
		border: 2px solid red !important;
	}
}

@media (max-width: rem-calc(540px)) {
	.drop_down {
		float: none;
		text-align: center;
		margin: 0 0 rem-calc(10px) 0;
		button {
			float: none;
			width: rem-calc(250px);
		}
	}
}
