@import '../../../../styles/Theme.Colors.module.scss';
@import '../../../../styles/Theme.Mixins.module.scss';

.actionButton {
	@include default-button();
	background: $ActionButtonPrimaryBackground;
	font-size: $DefaultFontSize;
	z-index: 0;
	&:hover {
		background: $ActionButtonPrimaryHoverBackground;
	}
	&.active:active {
		transform: translate(0px, 2px);
	}
	&.cancel {
		background: $ActionButtonCancelBackground;
		border: 1px solid $ActionButtonCancelBorderColour;
		color: $ActionButtonCancelTextColour;
		&:hover {
			background: $ActionButtonCancelHoverBackground;
		}
	}
	&.delete {
		background: $ActionButtonDeleteBackground;
		border: 1px solid $ActionButtonDeleteBorderColour;
		color: $ActionButtonDeleteTextColour;
		&:hover {
			background: $ActionButtonDeleteHoverBackground;
		}
	}
	&.highlight {
		border: 2px solid black;
	}
	&.no_box_shadow {
		box-shadow: 0px 0px 0px black;
		border-radius: $DefaultBorderRadius 0 0 $DefaultBorderRadius;
	}
	&.rounded {
		border-radius: rem-calc(14px);
	}
	&.secondary {
		background: $ActionButtonSecondaryBackground;
		border: 1px solid $ActionButtonSecondaryBorderColour;
		color: $ActionButtonSecondaryTextColour;
		&:hover {
			background: $ActionButtonSecondaryHoverBackground;
		}
	}
	&.small {
		font-size: $SmallFontSize;
	}
	&.small_fixed_width {
		@include small-fixed-width();
	}
	&.tall {
		padding: rem-calc(8px) rem-calc(16px) rem-calc(8px) rem-calc(16px) !important;
	}
}

.cancel {
	@include default-button();
	background: lighten($Red, 10%);
	padding: rem-calc(4px) rem-calc(16px) rem-calc(4px) rem-calc(16px) !important;
	&:hover {
		background: $Red;
	}
}
