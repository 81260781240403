@use 'sass:math' as math;

@import '../../../../styles/Theme.Colors.module.scss';
@import '../../../../styles/Theme.Mixins.module.scss';

@mixin default-connector($Height: rem-calc(40px)) {
	position: relative;
	width: 10%;
	top: rem-calc(0px);
	left: rem-calc(40px);
	height: $Height;
}

@mixin default-connector-line($Width: 36px, $Top: calc(50% + 2px)) {
	content: '';
	position: absolute;
	width: $Width;
	height: rem-calc(2px);
	top: $Top;
	right: rem-calc(8px);
	background: $Cello;
	z-index: 1;
}

.and_or_container {
	background: $White;
	border: rem-calc(1px) solid $ScienceBlue;
	border-radius: rem-calc(14px);
	width: fit-content;
}

.plus_or_minus {
	background: $White;
	border-radius: 0 60% 60% 0;
	color: $ScienceBlue;
	&:hover {
		cursor: pointer;
	}
}

.filter_type_button {
	box-shadow: rem-calc(0px) rem-calc(0px) rem-calc(0px) white !important;
}

.connector {
	@include default-connector();
}

.connector_first {
	@include default-connector($Height: rem-calc(30px));
}

.connector_or {
	@include default-connector($Height: rem-calc(20px));
}

.connector::after,
.connector_first::after,
.connector_or::after {
	content: '';
	position: absolute;
	width: rem-calc(2px);
	background: $Cello;
	top: 0;
	bottom: 0;
	margin-left: rem-calc(-1px);
}

// Represents the horizontal line
.connector_line_horizontal::before {
	@include default-connector-line();
}

.connector_line_horizontal_first::before {
	@include default-connector-line($Top: calc(50% - 15px));
}

.connector_line {
	padding: rem-calc(15px) rem-calc(30px);
	position: relative;
	background: inherit;
	width: 50%;
}

.connector_line.connector_line_horizontal::after,
.connector_line.connector_line_horizontal_first::after {
	left: rem-calc(-8px);
}

.connector_line.connector_line_horizontal::before,
.connector_line.connector_line_horizontal_first::before {
	left: 0;
}
