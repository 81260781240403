@import '../../../../styles/Theme.Colors.module.scss';
@import '../../../../styles/Theme.Mixins.module.scss';

.embed_container_hide {
	display: none;
}

.embed_container_show {
	display: block;
}

.overlay_container {
	width: 100%;
	height: 100vh;
}

.overlay {
	width: inherit;
	height: inherit;
	top: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
}
