@import '../../../styles/Theme.Colors.module.scss';
@import '../../../styles/Theme.Mixins.module.scss';

.welcome_container {
	@include default-box-header();
	display: grid;
	align-items: center;
	align-content: center;
	column-gap: $DefaultPadding;
	grid-template-columns: auto 1fr;
	justify-items: flex-start;
}

.report_class {
	iframe {
		height: calc((100vh - 30px)) !important;

		#pbiLoadingPlaceholder {
			display: none !important;
		}
	}
}
