@import '../../../../styles/Theme.Colors.module.scss';
@import '../../../../styles/Theme.Mixins.module.scss';

@mixin default-label() {
	@include default-button();
	width: 80px;
	text-align: center;
}

.label_left {
	@include default-label();
	border-radius: $DefaultBorderRadius 0 0 $DefaultBorderRadius;
}

.label_right {
	@include default-label();
	border-radius: 0 $DefaultBorderRadius $DefaultBorderRadius 0;
}
