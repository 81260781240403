@import './tooling/rem-calc.scss';

$AnimationTransitionSpeed: 0.25s;
$AnimationTimingFunction: cubic-bezier(1, 0, 0, 1);

$Small: 5px;
$Medium: 10px;
$MediumPlus: 15px;
$Large: 20px;
$XLarge: 30px;
$DefaultBorderRadius: rem-calc(4px);
$DefaultBoxShadow: 4px 4px 10px 4px rgba(0, 0, 0, 0.05);
$DefaultPadding: rem-calc(12px);
$DefaultFontSize: rem-calc(12px);
$DefaultFontWeight: 400;
$DefaultTableHeadFontWeight: 500;
$SmallFontSize: rem-calc(10px);
$SegmentBuilderSecondColumnWidth: 30%;
$SegmentBuilderThirdColumnWidth: 50%;
$LeftContainerWidth: rem-calc(256px);

@mixin animate($Property, $Speed: $AnimationTransitionSpeed) {
	transition: $Property $Speed;
	animation-timing-function: $AnimationTimingFunction;
}

@mixin full-width() {
	width: 100%;
}

@mixin flex-column-layout() {
	display: flex;
	flex-direction: column;
}

@mixin flex-row-layout() {
	display: flex;
	flex-direction: row;
}

@mixin flex-align-center() {
	align-items: center;
}

@mixin flex-align-end() {
	align-items: end;
}

@mixin flex-align-start() {
	align-items: start;
}

@mixin flex-justify-center() {
	justify-content: center;
}

@mixin flex-justify-bottom() {
	justify-content: end;
}

@mixin flex-justify-right() {
	justify-content: right;
}

@mixin flex-justify-left() {
	justify-content: left;
}

@mixin flex-full-center() {
	@include flex-align-center();
	@include flex-justify-center();
}

@mixin flex-left-center() {
	@include flex-align-start();
	@include flex-justify-center();
}

@mixin flex-bottom-left() {
	@include flex-align-start();
	// @include flex-justify-left();
}

@mixin medium-gap() {
	gap: rem-calc($Medium);
}

@mixin large-gap() {
	gap: rem-calc($Large);
}

@mixin no-gap() {
	gap: unset;
}

@mixin small-top-margin() {
	margin-top: rem-calc($Small);
}

@mixin medium-top-margin() {
	margin-top: rem-calc($Medium);
}

@mixin large-top-margin() {
	margin-top: rem-calc($Large);
}

@mixin xlarge-top-margin() {
	margin-top: rem-calc($XLarge);
}

@mixin medium-bottom-margin() {
	margin-bottom: rem-calc($Medium);
}

@mixin small-left-margin() {
	margin-left: rem-calc($Small);
}

@mixin medium-left-margin() {
	margin-left: rem-calc($Medium);
}

@mixin large-left-margin() {
	margin-left: rem-calc($Large);
}

@mixin small-right-margin() {
	margin-right: rem-calc($Small);
}

@mixin medium-plus-right-margin() {
	margin-right: rem-calc($MediumPlus);
}

@mixin medium-right-margin() {
	margin-right: rem-calc($Medium);
}

@mixin large-right-margin() {
	margin-right: rem-calc($Large);
}

@mixin small-bottom-margin() {
	margin-bottom: rem-calc($Small);
}

@mixin large-bottom-margin() {
	margin-bottom: rem-calc($Large);
}

@mixin medium-left-padding() {
	padding-left: rem-calc($Medium);
}

@mixin large-left-padding() {
	padding-left: rem-calc($Large);
}

@mixin large-right-padding() {
	padding-right: rem-calc($Large);
}

@mixin medium-top-padding() {
	padding-top: rem-calc($Medium);
}

@mixin large-top-padding() {
	padding-top: rem-calc($Large);
}

@mixin large-bottom-padding() {
	padding-bottom: rem-calc($Large);
}

@mixin large-vertical-padding() {
	@include large-left-padding();
	@include large-right-padding();
}

@mixin large-left-padding() {
	padding-left: rem-calc($Large);
}

@mixin small-full-padding() {
	padding: rem-calc($Small);
}

@mixin medium-full-padding() {
	padding: rem-calc($Medium);
}

@mixin large-full-padding() {
	padding: rem-calc($Large);
}

@mixin xlarge-full-padding() {
	padding: rem-calc($XLarge);
}

@mixin content-separator() {
	content: '';
	display: block;
	height: 1px;
	overflow: hidden;
	background: $Spindle;
	position: absolute;
	left: 0px;
	right: 0;
	bottom: 0;
}

@mixin no-padding-or-margin() {
	margin: unset;
	padding: unset;
}

@mixin border($BorderRadius: 6px) {
	border-radius: rem-calc($BorderRadius) !important;
	border: none;
}

@mixin shadow($inset-shadow: false) {
	$inset: if($inset-shadow == true, inset, null);
	box-shadow: $inset 0 rem-calc(2px) rem-calc(8px) rgb(0 0 0 / 26%);
}

@mixin border-no-shadow($border-radius: 6px) {
	@include border($border-radius);
	box-shadow: unset;
}

@mixin cursor-clickable-item() {
	cursor: pointer;
}

@mixin hover-shadow() {
	box-shadow: 0 rem-calc(2px) rem-calc(8px) $StandardHoverColor !important;
}

@mixin relative-position-and-top($top) {
	position: relative;
	top: $top;
}

@mixin negate-client-area-full-width {
	margin: rem-calc(-10px) rem-calc(-20px) rem-calc(0px) rem-calc(-20px);
	width: calc(100% + 40px);
}

@mixin no-data-container {
	@include flex-column-layout();
	@include flex-full-center();
	@include full-width();

	margin-top: rem-calc(50px);
	height: rem-calc(200px);
}

@mixin button-hover {
	background-color: $ActionButtonPrimaryHoverBackground;
	transition: 0.2s;
}

@mixin prevent-select {
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */
}

@mixin border-and-shadow(
	$BorderRadius: 6px,
	$HorOffset: 0,
	$VerOffset: 4px,
	$BlurRadius: 4px,
	$BorderColour: rgba(0, 0, 0, 0.2)
) {
	border-radius: $BorderRadius !important;
	box-shadow: $HorOffset $VerOffset $BlurRadius $BorderColour !important;
}

@mixin default-box() {
	background: $ContainerFillBackground;
	border-radius: $DefaultBorderRadius;
	box-shadow: $DefaultBoxShadow;
	color: $Cello;
	padding: rem-calc(12px);
}

@mixin default-box-header() {
	@include default-box();
}

@mixin default-check($border-color: $ScienceBlue) {
	content: '';
	position: absolute;
	left: rem-calc(8px);
	top: rem-calc(4px);
	width: rem-calc(5px);
	height: rem-calc(10px);
	border: solid $border-color;
	border-width: 0 rem-calc(3px) rem-calc(3px) 0;
	transform: rotate(45deg);
	opacity: 0;
}

@mixin disabled-bg-border() {
	background: $ScienceBlue;
	border-radius: $DefaultBorderRadius;
	border: 0;
	color: white;
	padding: rem-calc(4px) rem-calc(12px) rem-calc(4px) rem-calc(12px);
}

@mixin default-button() {
	background: $ScienceBlue;
	border-radius: $DefaultBorderRadius;
	border: 0;
	color: white;
	padding: rem-calc(4px) rem-calc(16px) rem-calc(4px) rem-calc(16px);
}

@mixin default-left-right-margin() {
	margin-left: rem-calc(12px);
	margin-right: rem-calc(12px);
}

@mixin default-table() {
	background-color: $ContainerFillBackground;
	border-radius: $DefaultBorderRadius !important;
	box-shadow: $DefaultBoxShadow;
	font-size: rem-calc(13px) !important;
	font-weight: $DefaultFontWeight;
}

@mixin trim-text() {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

@mixin scroll-bar() {
	overflow: auto;
	&::-webkit-scrollbar-track {
		border-radius: $DefaultBorderRadius * 2;
		background: $ScrollBarTrack;
		padding: rem-calc(4px);
		border: 2px solid $Botticelli;
	}
	&::-webkit-scrollbar {
		width: rem-calc(16px);
		border-radius: $DefaultBorderRadius * 2;
		background-color: #f5f5f5;
		padding: rem-calc(4px);
	}
	&::-webkit-scrollbar-thumb {
		border-radius: $DefaultBorderRadius * 2;
		background-color: $ScrollBarThumb;
		width: rem-calc(6px) !important;
	}
}

@mixin sticky() {
	position: -webkit-sticky; /* Safari */
	position: sticky;
	top: -5px;
	z-index: 99 !important;
}

@mixin small-fixed-width() {
	text-align: center;
	width: rem-calc(65px);
	padding: rem-calc(4px) !important;
}

@mixin fade-in-out() {
	animation: fadeInOut 1s ease-in-out infinite;
}

@keyframes fadeInOut {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
