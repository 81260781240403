@import '../../../../styles/Theme.Colors.module.scss';
@import '../../../../styles/Theme.Mixins.module.scss';

.collapse_button_container {
	.collapse_button {
		@include animate(margin-right);
		background: $CollapseButtonBackground;
		box-shadow: $DefaultBoxShadow;
		color: $DefaultFontColour;
		padding: rem-calc(4px) !important;
		margin-right: 0px;
		&.collapsed {
			margin-top: rem-calc(4px);
			margin-right: rem-calc(-20px);
		}
		&:hover {
			background: $CollapseButtonHoverBackground;
			color: $CollapseButtonHoverTextColour;
		}
	}
	&.vertical {
		margin: 0 !important;
	}
}
