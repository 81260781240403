@import '../../../styles/Theme.Colors.module.scss';
@import '../../../styles/Theme.Mixins.module.scss';

.chart_modal_container {
	// display: grid !important;
	max-height: calc(100vh - (200px));
}

.card_container {
	background: $CatskillWhite;
	border: 0;
	border-radius: $DefaultBorderRadius;
	height: 235px !important;
}

.median_header_value {
	display: flex;
	justify-content: space-between;
}

.chart_container {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-bottom: 20px;
	canvas {
		display: grid !important;
	}
}
