@import '../../../../styles/Theme.Colors.module.scss';
@import '../../../../styles/Theme.Mixins.module.scss';

.checkbox_label {
	input {
		position: absolute;
		opacity: 0;
		height: 0;
		width: 0;
	}

	label {
		position: relative;
		display: block;
		font-size: rem-calc(14px);
		padding-left: rem-calc(30px);
		cursor: pointer;
		text-align: left;
	}

	label:before {
		content: '';
		position: absolute;
		top: 50%;
		margin-top: rem-calc(-10px);
		left: 0;
		display: block;
		height: rem-calc(20px);
		width: rem-calc(20px);
		background-color: $CheckboxCheckBackground;
		border: 2px solid $CheckboxCheckBorderColour;
		border-radius: rem-calc(3px);
	}

	label:hover:before {
		background-color: $CheckboxCheckHoverBackground;
	}

	label:after {
		@include default-check();
	}

	label:hover:after {
		@include default-check($White);
	}

	input:checked + label:after {
		opacity: 1;
	}
}
