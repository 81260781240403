@import './tooling/colors.scss';

$PrimaryColourOne: $DodgerBlue;
$PrimaryColourTwo: $KeyLimePie;
$PrimaryColourThree: $Turbo;

$DefaultFontColour: $LimedSpruce;

$ContainerFillBackground: $White;
$ContainerProminentFillBackground: lighten($ScienceBlue, 15%);
$ContainerTitleBackColor: $Ghost;
$LeftContainerBackground: $White;
$RightContainerBackground: $CatskillWhite;

$ActionButtonPrimaryBackground: $ScienceBlue;
$ActionButtonPrimaryHoverBackground: lighten($ScienceBlue, 20%);
$ActionButtonPrimaryTextColour: $White;

$ActionButtonCancelBackground: $White;
$ActionButtonCancelHoverBackground: darken($White, 10%);
$ActionButtonCancelTextColour: $DefaultFontColour;
$ActionButtonCancelBorderColour: $LimedSpruce;

$ActionButtonDeleteBackground: $White;
$ActionButtonDeleteHoverBackground: darken($White, 10%);
$ActionButtonDeleteTextColour: $Red;
$ActionButtonDeleteBorderColour: $Red;

$ActionButtonSecondaryBackground: $White;
$ActionButtonSecondaryHoverBackground: darken($White, 10%);
$ActionButtonSecondaryTextColour: $ScienceBlue;
$ActionButtonSecondaryBorderColour: $ScienceBlue;

$CheckboxCheckBackground: $White;
$CheckboxCheckBorderColour: $ScienceBlue;
$CheckboxCheckHoverBackground: $PeriwinkleGray;

$CollapseButtonBackground: $Geyser;
$CollapseButtonHoverBackground: darken($Geyser, 10%);
$CollapseButtonHoverTextColour: $White;

$DropDownBackground: $White;

$IconColour: $GullGray;

$LabelPrimaryBackground: $ScienceBlue;
$LabelSecondaryBackground: $White;
$LabelSecondaryTextColour: $ScienceBlue;
$LabelSecondaryBorderColour: $ScienceBlue;

$ModalBackgroundColor: $White;
$ModalCloseBackgroundColor: $White;
$ModalCloseColor: $SpaceBlue;
$ModalProminentTitleBackground: $White;
$ModalProminentTitleColor: $White;
$ModalProminentCloseColor: $Steel;
$ModalProminentCloseHoverColor: $DarkSteel;
$ModalProminentDividerColor: $Lime;

$NavigationListItemBackground: $WhiteLilac;
$NavigationListItemLeftBorder: $ScienceBlue;

$ProminentFieldBackground: $White;
$ProminentFieldColor: $Steel;

$RadioButtonBorderColour: darken($Geyser, 10%);

$SegmentFilterSelectionsBackground: $White;
$SegmentFilterSelectionsItemBackground: $Mystic;

$SectionDivider: $White;
$ScrollBarThumb: darken($Geyser, 10%);
$ScrollBarTrack: $White;

$StatusTitleColor: #5b6c81;

$TableHeadBackground: $WhiteLilac;
$TableHeadFontColour: $CuttySark;

$TabHeadersColor: $Steel;
$TabSelectedTabDividerColor: $Yella;

$DataItemBackgroundColor: #f3f4f5;
$DataItemTitleBackgroundColor: #bbddf6;
$TextBoxCharacterCountColor: lighten($Steel, 20%);
$TextStatusColor: #73859c;
$SchedulerUnavailableResourceBackgroundColor: lighten($Yella, 15%);
$SchedulerControlsFontColor: $Steel;
$SchedulerAppointmentBackgroundColor: #f2f2f2;
$SchedulerResourceCountsBackgroundColor: lighten($Yella, 15%);
$SchedulerResourceCountsColor: $Steel;
$SchedulerResourceCountsAtMaxBackgroundColor: red;
$SchedulerResourceCountsAtMaxColor: white;
$ProminentIconColor: $Yella;
$SwitchSliderBackgroundColor: lightgray;
$SwitchOffColor: grey;
$SwitchOnColor: $ActionButtonPrimaryBackground;
$DynamicFormSaveBackgroundColor: $Yella;
$DynamicFormSaveIconColor: $ActionButtonPrimaryBackground;
$DynamicFormSaveDisabledBackgroundColor: lightgrey;
$DynamicFormSaveDisabledIconColor: white;
$OverlayDisabledBackgroundColor: white;
$WarningFieldTextColour: white;
$WarningFieldBackgroundColor: rgba(255, 199, 33, 255);
$GridBackground: $White;
$GridHeaderBackground: #f2f2f2;
$GridRowBackground: $White;
$GridRowInactiveBackground: darken(
	$color: $GridRowBackground,
	$amount: 10,
);
$GridRowHighlightBackground: lighten(
	$color: $ContainerProminentFillBackground,
	$amount: 10,
);
$GridTagFontColor: #666;
$GridTagBackground: #f2f2f2;
