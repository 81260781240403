@import '../../../../styles/Theme.Colors.module.scss';
@import '../../../../styles/Theme.Mixins.module.scss';

.modal_page_overlay {
	background: rgba(0, 0, 0, 0.5);
	top: 0;
	left: 0;
	height: 100vh;
	width: 100%;
	position: fixed;
	z-index: 200;
	pointer-events: auto;
	opacity: 0;
	@include animate(opacity);
	&.visible {
		opacity: 1;
	}
}

.modal_container_scroll {
	@include scroll-bar();
	overflow-x: hidden !important;
	overflow-y: auto;
}

.modal_container {
	@include animate(opacity, $AnimationTransitionSpeed * 2);
	position: fixed;
	top: 0px;
	left: 0px;
	height: 100vh;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 300;
	pointer-events: none;
	opacity: 0;

	&.visible {
		opacity: 1;
	}

	.modal {
		@include shadow();
		pointer-events: auto;

		position: absolute;
		width: 40rem;
		left: calc(50% - 20rem);
		min-height: rem-calc(120px);
		background-color: $ModalBackgroundColor;
		border-radius: rem-calc(10px);

		.modal_header {
			@include full-width();
		}

		&.small {
			width: 30rem !important;
			left: calc(50% - 15rem) !important;
		}

		&.off_center_top {
			top: 20%;
		}

		&.standard {
			@include large-full-padding();

			.modal_header {
				height: rem-calc(40x);

				.title {
					font-size: $DefaultFontSize;
					margin-left: 0.5rem;
					margin-top: 0.7rem;
				}

				.close_button_container {
					text-align: right;

					.close_button {
						background: $ModalCloseBackgroundColor !important;
						border: none;
						color: $ModalCloseColor !important;
					}
				}
			}
		}

		&.prominent {
			.modal_header {
				@include flex-column-layout();
				@include flex-full-center();
				@include large-top-padding();

				background-color: $ModalProminentTitleBackground;
				color: $ModalProminentTitleColor;
				height: rem-calc(120.5px);

				border-top-left-radius: 10px;
				border-top-right-radius: 10px;

				border-bottom: $ModalProminentDividerColor solid rem-calc(3px);

				.title {
					font-size: rem-calc(28px);
					color: white;
					font-weight: $DefaultFontWeight;
					font-style: normal;
					letter-spacing: -0.04em;
				}

				.close_button_container {
					text-align: right;

					.close_button {
						background: $ModalCloseBackgroundColor !important;
						border: none;
						color: $ModalProminentCloseColor !important;
						border-radius: 50%;
						position: absolute;
						right: rem-calc(10px);
						top: rem-calc(10px);
						width: rem-calc(20px);
						height: rem-calc(20px);
						padding: 0px;

						svg {
							margin-top: rem-calc(-6px);
							stroke-width: 0.5;
						}

						&:hover {
							color: $ModalProminentCloseHoverColor;
						}
					}
				}
			}
		}
	}
}
