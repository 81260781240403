@use 'sass:math' as math;

@import '../../../styles/Theme.Colors.module.scss';
@import '../../../styles/Theme.Mixins.module.scss';

.primary_column {
	label {
		display: grid;
		border: 1px solid $PoloBlue;
		border-radius: $DefaultBorderRadius;
		padding: rem-calc(12px);
	}
}

.second_column {
	padding-top: rem-calc(16px);
	width: $SegmentBuilderSecondColumnWidth;
}

.third_column {
	padding-top: rem-calc(16px);
	width: $SegmentBuilderThirdColumnWidth;
}

.or_container {
	margin-left: rem-calc(110px);
}
