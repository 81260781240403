@import '../../../../styles/Theme.Colors.module.scss';
@import '../../../../styles/Theme.Mixins.module.scss';

.loader_container {
	@include default-box();
	display: grid;
	align-items: center;
	column-gap: $DefaultPadding;
	grid-template-columns: auto auto;
	justify-content: flex-start;
}

.loader_container_centered {
	display: grid;
	justify-items: center;
	row-gap: 12px;
}

.loader {
	height: 50px;
	color: white;
}

.simple {
	height: rem-calc(20px);
}
