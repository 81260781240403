@import '../../../../styles/Theme.Colors.module.scss';
@import '../../../../styles/Theme.Mixins.module.scss';

.segment_filter_selections {
	@include scroll-bar();
	background: $SegmentFilterSelectionsBackground;
	border: 1px solid $Spindle;
	border-radius: $DefaultBorderRadius;
	font-size: $DefaultFontSize;
	list-style-type: none;
	margin-bottom: 0px;
	padding: rem-calc(6px);
	max-height: 30vh;
	&.clickable {
		&:hover {
			cursor: pointer;
		}
	}
	&.display_first_item_only {
		padding: 0;
		li {
			padding: rem-calc(2px) rem-calc(4px) rem-calc(2px) rem-calc(4px);
		}
	}
	&.scrollable {
		overflow-y: auto;
	}
	li {
		background-color: $SegmentFilterSelectionsItemBackground;
		border-radius: $DefaultBorderRadius;
		color: $DefaultFontColour;
		display: inline-block;
		padding: rem-calc(4px) rem-calc(12px) rem-calc(6px) rem-calc(12px);
		margin: rem-calc(2px);
		span.remove_selection {
			margin-right: -6px;
			margin-left: 4px;
			padding: rem-calc(1px) rem-calc(4px) rem-calc(5px) rem-calc(4px);
			border-radius: $DefaultBorderRadius;
			&:hover {
				background-color: lighten($ScienceBlue, 25%);
				color: $White;
				cursor: pointer;
			}
		}
	}
}
