@import '../../../../styles/Theme.Colors.module.scss';
@import '../../../../styles/Theme.Mixins.module.scss';

.first_filter_group {
	margin-top: rem-calc(15px);
}

.segment_applied_filter_container {
	border: rem-calc(1px) solid $PoloBlue;
	border-radius: $DefaultBorderRadius;
	font-size: $DefaultFontSize;
	font-weight: $DefaultFontWeight;
	height: fit-content;
	padding: rem-calc(5px);
	text-align: center;
	&:hover {
		cursor: pointer;
	}
	span {
		@include trim-text();
	}
}
