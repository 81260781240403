@import '../../../../styles/Theme.Colors.module.scss';
@import '../../../../styles/Theme.Mixins.module.scss';

.table_head {
	@include sticky();
	background: $TableHeadBackground;
	border-radius: $DefaultBorderRadius $DefaultBorderRadius 0 0;
	tr {
		text-align: left;
		th.default_heading {
			&__sort {
				background: darken($ContainerFillBackground, 10%);
			}
		}
		th {
			background: $TableHeadBackground;
			color: $TableHeadFontColour;
			font-weight: $DefaultTableHeadFontWeight;
			padding: rem-calc(12px);
			position: relative;
		}
		th.default_heading:first-child:before {
			left: 20px;
			right: 0;
		}
		th.heading_only:first-child {
			border-radius: $DefaultBorderRadius 0px 0px 0px !important;
		}
		th.default_heading:first-child {
			border-radius: $DefaultBorderRadius 0px 0px 0px !important;
		}
		th.heading_only:last-child {
			border-radius: 0px $DefaultBorderRadius $DefaultBorderRadius 0px !important;
		}
		th.default_heading:last-child {
			border-radius: 0px $DefaultBorderRadius 0px 0px !important;
		}
		th.default_heading:last-child:before {
			left: 0;
			right: 20px;
		}
	}
}
