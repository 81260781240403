@import '../../../../styles/Theme.Colors.module.scss';
@import '../../../../styles/Theme.Mixins.module.scss';

.label {
	font-size: $DefaultFontSize;
	font-weight: $DefaultFontWeight;
	color: $Cello;
	&.bold {
		font-weight: bold;
	}
	&.clickable {
		cursor: pointer;
	}
	&.disabled {
		background: $White;
		color: $ScienceBlue !important;
		border: 1px solid $LabelSecondaryBorderColour !important;
		&:hover {
			background: $ActionButtonSecondaryHoverBackground;
		}
	}
	&.secondary {
		@include default-button();
		color: $LabelSecondaryTextColour !important;
		background: $LabelSecondaryBackground !important;
		border: 1px solid $LabelSecondaryBorderColour !important;
		text-align: center;
	}
	&.selected {
		background: $LabelPrimaryBackground !important;
		color: $White !important;
	}
	&.small {
		font-size: $SmallFontSize;
	}
	&.small_fixed_width {
		@include small-fixed-width();
	}
	&.simple {
		border: 0;
		padding: rem-calc(2px) rem-calc(14px) rem-calc(2px) rem-calc(14px);
	}
	&.underline {
		border-bottom: 5px solid $ScienceBlue;
	}
	&.white_text {
		color: $White !important;
	}
	&.red_text {
		color: $Red !important;
	}
}
